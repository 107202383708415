import { request } from "graphql-request";
import getFeaturedArticles from "@/graphql/queries/getFeaturedArticles";
import getConfig from "next/config";
import getSportsPN from "@/graphql/queries/getPlayerNewsSports";
import getRedirectQuery from "@/graphql/queries/getRedirectQuery";
import getHomepageQuery from "@/graphql/queries/getHomepageQuery";
import getArticlesQuery from "@/graphql/queries/getArticlesQuery";
import getArticleBySlugQuery from "@/graphql/queries/getArticleBySlugQuery";
import getAuthorsQuery from "@/graphql/queries/getAuthorsQuery";
import getMenusQuery from "@/graphql/queries/getMenus";
import getShortFormsQuery from "@/graphql/queries/getShortFormsQuery";
import getActiveFixturesQuery from "@/graphql/queries/getActiveFixturesQuery";
import getSubCategoryQuery from "@/graphql/queries/getSubCategoryQuery";
import {
  QueryGetSingleArticleArgs,
  ArticleSearchInput,
  PageType,
  QueryGetSupportedSportsArgs,
  AuthorSearchInput,
  ContentAuthorAuthoredContentArgs,
  QueryGetHomepageArgs,
  QueryGetProjectionTableMetadataArgs,
  QueryGetSlatesArgs,
  ProjectionsInput,
  ContentSportsEnum,
  PositionAbbrev,
  ShortFormNewsTypeEnum,
  ShortFormSearchInput,
  PromotionsInput,
  GetSingleAuthorQueryQueryVariables,
} from "@/graphql/gql/graphql";
import getArticleInfoByDateRangeQuery from "@/graphql/queries/getArticleInfoByDateRangeQuery";
import getTentpoleEventQuery from "@/graphql/queries/getTentpoleEventQuery";
import getProjectionMetaDataQuery from "@/graphql/queries/getProjectionMetaDataQuery";
import getSlatesQuery from "@/graphql/queries/getSlatesQuery";
import getProjectionsQuery from "@/graphql/queries/getProjectionsQuery";
import getGolfEventsQuery from "@/graphql/queries/getGolfEventsQuery";
import getAllTentpoleEvents from "@/graphql/queries/getAllTentpoleEventsQuery";
import getShortFormQuery from "@/graphql/queries/getShortFormQuery";
import getSupportedSportsFilters from "@/graphql/queries/getSupportedSports/getSupportedSportsFilters";
import getSports from "@/graphql/queries/getSupportedSports/getSports";
import getHorseRacesQuery from "@/graphql/queries/getHorseRacesQuery";
import getRaceByTrackQuery from "@/graphql/queries/getRaceByTrackQuery";
import getTrackRacesQuery from "@/graphql/queries/getTrackRacesQuery";
import getPlayerNewsHomeQuery from "@/graphql/queries/getPlayerNewsHome";
import getHorseTrackListQuery from "@/graphql/queries/getHorseTrackListQuery";
import getRunnerStatsQuery from "@/graphql/queries/getRunnerStatsQuery";
import getHorseRacesByTrackQuery from "@/graphql/queries/getHorseRacesByTrackQuery";
import getTrackDetailsQuery from "@/graphql/queries/getTrackDetailsQuery";
import getSitemapShortFormsQuery from "@/graphql/queries/getSitemapShortFormsQuery";
import getSiteSettingsQuery from "@/graphql/queries/getSiteSettingsQuery";

import getContentFilters from "../graphql/queries/getContentFilters/getContentFilters";
import { getPromotionsQuery } from "@/graphql/queries/getPromotionsQuery";
import getSingleAuthorQuery from "@/graphql/queries/getSingleAuthorQuery";
import getSubCategoriesQuery from "@/graphql/queries/getSubCategoriesQuery";

const DEFAULT_TIMEOUT = 15000;

export function WrapperSignalTimeout() {
  return AbortSignal.timeout(DEFAULT_TIMEOUT) as never;
}

export const getOrchestrationEndpoint = () => {
  const { publicRuntimeConfig } = getConfig() || {};
  const { ORCHESTRATION_ENDPOINT } = publicRuntimeConfig || {};
  return ORCHESTRATION_ENDPOINT || "http://localhost:3000/api";
};

export function getMenus(isPreview?: boolean) {
  return request({
    url: getOrchestrationEndpoint(),
    variables: { isPreview },
    document: getMenusQuery,
    signal: WrapperSignalTimeout(),
  });
}

export function getArticleBySlug({
  slug,
  contentStatus,
  isPreview,
}: QueryGetSingleArticleArgs) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getArticleBySlugQuery,
    variables: {
      slug,
      contentStatus,
      isPreview,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getAllArticles({
  limit = 10,
  afterCursor = null,
  sportName = null,
  hideDeprioritizedContent = null,
  ...rest
}: ArticleSearchInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getArticlesQuery,
    variables: {
      filter: {
        limit,
        sportName,
        afterCursor,
        hideDeprioritizedContent,
        ...rest,
      },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getArticleInfoByDateRange({
  publishedWithin,
  limit = 0,
}: ArticleSearchInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getArticleInfoByDateRangeQuery,
    variables: {
      filter: {
        publishedWithin,
        limit,
      },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getArticlesBySport(sports: string[]) {
  return Promise.all(
    sports.map((sportName) =>
      request({
        url: getOrchestrationEndpoint(),
        document: getArticlesQuery,
        variables: {
          filter: {
            sportName,
            afterCursor: "",
          },
        },
        signal: WrapperSignalTimeout(),
      })
    )
  );
}

export function getFeaturedArticlesBySport(sportSlugs: string[]) {
  return Promise.all(
    sportSlugs.map((sportSlug) =>
      request({
        url: getOrchestrationEndpoint(),
        document: getFeaturedArticles,
        variables: {
          filter: {
            sportSlug,
            pageType: sportSlug ? PageType.Sport : PageType.Homepage,
          },
        },
        signal: WrapperSignalTimeout(),
      })
    )
  );
}

export function getSubCategory(sport: string, subCategory: string) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSubCategoryQuery,
    variables: {
      input: {
        sportName: sport,
        slug: subCategory,
      },
      filter: {
        limit: 16,
        subCategorySlug: subCategory,
      },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getSubCategories() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSubCategoriesQuery,
    signal: WrapperSignalTimeout(),
  });
}
interface GetShortFormsParams {
  filter: GetShortFormsParamsFilter;
  limit?: number;
}
interface GetShortFormsParamsFilter {
  start?: string;
  end?: string;
  sportName?: ContentSportsEnum;
  cursor?: string | null;
  teamNumberFireId?: number | null;
  positionAbbrev?: PositionAbbrev | null;
  shortFormNewsType?: ShortFormNewsTypeEnum | null;
}

export async function getShortForms({ filter, limit }: GetShortFormsParams) {
  try {
    const {
      sportName,
      cursor,
      positionAbbrev,
      shortFormNewsType,
      teamNumberFireId,
    } = filter;

    const queryParams = {
      filter: {
        limit,
        sport: sportName,
        afterCursor: cursor,
        team: {
          numberFireId: teamNumberFireId,
        },
        player: {
          positionAbbrev,
        },
        shortFormNewsType,
      },
    };

    const response = await request({
      url: getOrchestrationEndpoint(),
      document: getShortFormsQuery,
      variables: queryParams,
      signal: WrapperSignalTimeout(),
    });

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in getShortForms API request:", {
      error,
      url: getOrchestrationEndpoint(),
    });
    throw error;
  }
}

export async function getSitemapShortForms({
  filter,
  limit,
}: GetShortFormsParams) {
  try {
    const {
      sportName,
      cursor,
      positionAbbrev,
      shortFormNewsType,
      teamNumberFireId,
      start,
      end,
    } = filter;

    const publishedWithin =
      start && end
        ? {
            start,
            end,
          }
        : undefined;

    const queryFilter: ShortFormSearchInput = {
      limit,
      sport: sportName,
      afterCursor: cursor,
      team: {
        numberFireId: teamNumberFireId,
      },
      publishedWithin,
      player: {
        positionAbbrev,
      },
      shortFormNewsType,
    };
    const queryParams = {
      filter: queryFilter,
    };

    const response = await request({
      url: getOrchestrationEndpoint(),
      document: getSitemapShortFormsQuery,
      variables: queryParams,
      signal: WrapperSignalTimeout(),
    });

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in getSitemapShortForms API request:", error);
    throw error;
  }
}
export function getAuthors({
  contentType,
  sportName,
  sport,
  afterCursor,
  limit = 12,
}: AuthorSearchInput & ContentAuthorAuthoredContentArgs) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getAuthorsQuery,
    variables: {
      input: { sportName, afterCursor, limit },
      contentType,
      sport,
    },
    signal: WrapperSignalTimeout(),
  });
}
export function getSingleAuthor({ slug }: GetSingleAuthorQueryQueryVariables) {
  return request(getOrchestrationEndpoint(), getSingleAuthorQuery, {
    slug,
  });
}

export function getScores(sport?: string) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getActiveFixturesQuery,
    variables: {
      input: { sport },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getPlayerNewsHome({ sport, limit = 9 }: ShortFormSearchInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getPlayerNewsHomeQuery,
    variables: {
      filter: {
        sport,
        limit,
      },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getAvailableSports({
  isPreview,
  featured,
}: QueryGetSupportedSportsArgs = {}) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSports,
    variables: {
      isPreview,
      featured,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getAvailableSportsPN({
  isPreview,
  featured,
}: QueryGetSupportedSportsArgs = {}) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSportsPN,
    variables: {
      isPreview,
      featured,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getRedirect(slug: string) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getRedirectQuery,
    variables: {
      input: { slug },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getHomepage({ isPreview }: QueryGetHomepageArgs) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getHomepageQuery,
    variables: { isPreview },
    signal: WrapperSignalTimeout(),
  });
}

export function getTentpoleEvent(slug = "", sportSlug = "") {
  return request({
    url: getOrchestrationEndpoint(),
    document: getTentpoleEventQuery,
    variables: {
      input: { slug, sportSlug },
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getTentpoleEvents() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getAllTentpoleEvents,
    signal: WrapperSignalTimeout(),
  });
}

export function getProjectionInfo(args: QueryGetProjectionTableMetadataArgs) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getProjectionMetaDataQuery,
    variables: args,
    signal: WrapperSignalTimeout(),
  });
}

export function getSlates(variables: QueryGetSlatesArgs) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSlatesQuery,
    variables,
    signal: WrapperSignalTimeout(),
  });
}

export function getGolfEvents() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getGolfEventsQuery,
    signal: WrapperSignalTimeout(),
  });
}

export function getProjectionData(args: ProjectionsInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getProjectionsQuery,
    variables: {
      input: args,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getShortForm(slug: string) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getShortFormQuery,
    variables: { slug },
    signal: WrapperSignalTimeout(),
  });
}

export function getSupportedSportsFiltersApi() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSupportedSportsFilters,
    signal: WrapperSignalTimeout(),
  });
}

export function getContentFiltersApi() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getContentFilters,
    signal: WrapperSignalTimeout(),
  });
}

export function getUpcomingHorseRaces({ current = 0, results = 10 }) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getHorseRacesQuery,
    variables: {
      profile: "FDR-Generic",
      current,
      results,
    },
    signal: WrapperSignalTimeout(),
  });
}
interface GetRaceByTrackInput {
  trackCode: string;
  raceNumber: number;
}
export function getRaceByTrack({ trackCode, raceNumber }: GetRaceByTrackInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getRaceByTrackQuery,
    variables: {
      profile: "FDR-Generic",
      trackCode,
      raceNumber,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getTrackRaces({ trackCode }: { trackCode: string }) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getTrackRacesQuery,
    variables: {
      profile: "FDR-Generic",
      trackCode,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getHorseTrackList() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getHorseTrackListQuery,
    variables: {
      profile: "FDR-Generic",
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getRunnerStats({ runnerName }: { runnerName: string }) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getRunnerStatsQuery,
    variables: {
      profile: "FDR-Generic",
      runnerName,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getAllRacesByTrack({
  trackCode,
}: Partial<GetRaceByTrackInput>) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getHorseRacesByTrackQuery,
    variables: {
      profile: "FDR-Generic",
      trackCode,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getTrackDetails({ trackCode }: { trackCode: string }) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getTrackDetailsQuery,
    variables: {
      profile: "FDR-Generic",
      trackCode,
    },
    signal: WrapperSignalTimeout(),
  });
}

export function getSiteSettings() {
  return request({
    url: getOrchestrationEndpoint(),
    document: getSiteSettingsQuery,
    signal: WrapperSignalTimeout(),
  });
}

export function getPromotions({ tags }: PromotionsInput) {
  return request({
    url: getOrchestrationEndpoint(),
    document: getPromotionsQuery,
    variables: {
      input: { tags },
    },
    signal: WrapperSignalTimeout(),
  });
}
